import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import { NavListProps } from 'src/components/nav-section';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  ticket: icon('ic_ticket'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  setting: icon('ic_setting'),
  manage: icon('ic_setting'),
  release: icon('ic_release'),
};

interface NavData {
  subheader: string;
  items: NavListProps[];
}

function NavChildrenFilter(items: NavListProps, permissions: string[]) {
  const children: NavListProps[] = items?.children || [];
  const hasPermission =
    children.length === 0 &&
    (!items?.permissions || items?.permissions.filter((f) => permissions.includes(f)).length > 0);

  if (children.length > 0) {
    const newChildren = children
      .map((m) => NavChildrenFilter(m, permissions))
      .filter((f) => !!f) as any[];
    return newChildren.length > 0 ? { ...items, children: newChildren || undefined } : null;
  }

  return hasPermission ? { ...items, children: undefined } : null;
}

function NavItemFilter(nav: NavData, permissions: string[]) {
  const items = (nav.items || []).map((m) => NavChildrenFilter(m, permissions)).filter((f) => !!f);
  return items.length > 0 ? { ...nav, items: items } : null;
}

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { permissions, role, user, isAdmin, isSpecialUser, isRoleDataEntry } = useAuthContext();
  const isAgentType = user?.user_type === 'AGENT';
  const isSpecificUser = user?.id === 1278 || user?.id === 1247;
  const data = useMemo(
    () =>
      [
        // OVERVIEW
        // ----------------------------------------------------------------------
        {
          subheader: t('overview'),
          items: [{ title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard }],
        },
        {
          subheader: 'report',
          items: [
            {
              title: t('report'),
              path: paths.dashboard.report.root,
              icon: ICONS.analytics,
              children: [
                {
                  title: t('daily_detail_report'),
                  path: paths.dashboard.report.root,
                  permissions: ['daily_detail_report_view'],
                },
                ...(isAgentType
                  ? []
                  : [
                      {
                        title: t('daily_bet_report'),
                        path: paths.dashboard.report.bet,
                        permissions: ['daily_report_view'],
                      },
                    ]),
                ...(isAgentType || isAdmin
                  ? [
                      {
                        name: 'agent',
                        title: t('agent_report.root'),
                        path: paths.dashboard.report.agent,
                      },
                    ]
                  : []),
                ...(user?.id == 1278
                  ? [
                      {
                        name: 'agent-profit',
                        title: t('agent_report.agent_profit'),
                        path: paths.dashboard.report.agentProfit,
                      },
                    ]
                  : []),
                ...(!isAgentType
                  ? [
                      {
                        name: 'user',
                        title: t('daily_user_report'),
                        path:
                          role === 'member'
                            ? paths.dashboard.report.userDetailV2(user?.id)
                            : paths.dashboard.report.user,
                      },
                    ]
                  : []),

                {
                  name: 'cash',
                  title: t('cash_report'),
                  path: paths.dashboard.report.cash,
                  permissions: ['agent_user_view'],
                },
                {
                  title: t('daily_bet_over_limit'),
                  path: paths.dashboard.report.overLimit,
                  permissions: ['break_number_ticket_view'],
                },

                {
                  title: t('ticket_report'),
                  path: paths.dashboard.report.ticket(user?.id),
                  permissions: ['daily_report_view'],
                },

                {
                  title: t('daily_dealer_report'),
                  path: paths.dashboard.report.dealer,
                  permissions: ['break_number_ticket_view'],
                },
                ...(role === 'senior' ||
                role === 'master' ||
                role === 'admin' ||
                role === 'super_admin'
                  ? [
                      {
                        title: t('top_bet_report'),
                        path: paths.dashboard.report.topBet,
                      },
                    ]
                  : []),
              ],
            },
          ],
        },
        {
          subheader: 'lotto',
          items: [
            {
              // isFullScreen: true,
              title: t('entry_form'),
              path: paths.dashboard.ticket.root,
              icon: ICONS.blank,
              children: [
                {
                  title: t('create_ticket'),
                  isFullScreen: true,
                  path: paths.dashboard.ticket.root,
                  permissions: ['create_ticket_view'],
                },
                // {
                //   title: t('Edit ticket'),
                //   path: paths.dashboard.ticket.edit,
                // },
                {
                  title: t('break_number'),
                  path: paths.dashboard.ticket.break,
                  permissions: ['break_number_ticket_view'],
                },
              ],
            },
          ],
        },

        {
          subheader: 'user',
          items: [
            {
              title: t('user'),
              path: paths.dashboard.user.root,
              icon: ICONS.user,
              children: [
                {
                  title: t('admin'),
                  path: paths.dashboard.user.root,
                  permissions: ['admin_user_view'],
                },
                {
                  title: t('agent'),
                  path: paths.dashboard.user.agent,
                  permissions: ['agent_user_view'],
                },
                // {
                //   title: t('sub_agent'),
                //   path: paths.dashboard.user.sub,
                //   permissions: ['agent_user_view'],
                // },
                {
                  title: t('dealer'),
                  path: paths.dashboard.user.dealer,
                  permissions: ['dealer_user_view'],
                },
                {
                  title: t('group permission'),
                  path: paths.dashboard.user.permission,
                  permissions: ['group_permission_user_view'],
                },
              ],
            },
            // {
            //   title: t('lotto result'),
            //   path: paths.dashboard.empty,
            //   icon: ICONS.blog,
            //   children: [
            //     { title: t('lotto retult 5D'), path: paths.dashboard.empty },
            //     { title: t('lotto result VN'), path: paths.dashboard.empty },
            //     { title: t('release lotto 5D'), path: paths.dashboard.empty },
            //     { title: t('release lotto VN'), path: paths.dashboard.empty },
            //   ],
            // },
          ],
        },
        {
          subheader: 'result',
          items: [
            {
              title: t('result'),
              path: paths.dashboard.result.root(''),
              icon: ICONS.blog,
              children: [
                {
                  title: t('release_result'),
                  path: paths.dashboard.result.root(''),
                },

                ...(isSpecificUser
                  ? [
                      {
                        title: t('generate_result'),
                        path: paths.dashboard.result.generate,
                      },
                    ]
                  : []),
              ],
            },
          ],
        },
        {
          subheader: 'configuration',
          items: [
            {
              title: t('setting'),
              path: paths.dashboard.setting.root,
              icon: ICONS.setting,
              children: [
                {
                  title: t('result_time'),
                  path: paths.dashboard.setting.root,
                  permissions: ['result_time_setting_view'],
                },
                {
                  title: t('user_zone'),
                  path: paths.dashboard.setting.zone,
                  permissions: ['user_zone_setting_view'],
                },
                ...(isAdmin
                  ? [
                      {
                        title: t('transfer_user'),
                        path: paths.dashboard.manage.updateUser,
                      },
                    ]
                  : []),
              ],
            },
          ],
        },
        ...(isSpecialUser || isRoleDataEntry
          ? [
              {
                subheader: t('manage'),
                items: [
                  {
                    title: t('manage'),
                    path: paths.dashboard.manage.ticketTime,
                    icon: ICONS.setting,
                    children: [
                      {
                        title: t('ticket_time'),
                        path: paths.dashboard.manage.ticketTime,
                      },
                      {
                        title: t('transfer_ticket'),
                        path: paths.dashboard.manage.transferTicket,
                      },
                    ],
                  },
                ],
              },
            ]
          : []),
      ]
        .map((m) => NavItemFilter(m, permissions))
        .filter((f) => {
          return !!f;
        }) as NavData[],
    [t, permissions]
  );
  return data;
}
